import Vapor from 'laravel-vapor'
import { useToast } from '@chakra-ui/react'
import { uploadFileBySolution } from '@/hooks/solutions'
import { IDocumentUploaded } from '@/interfaces/common'
import axios from 'axios'

export const formatPhoneNumber = (value: string) => {
    if (value.length < 6) return value

    const internationalCode = value.slice(0, 3)
    const code = value.slice(3, 6)
    return `${internationalCode} ${code} ${value
        .slice(6)
        .replace(/\W/gi, '')
        .replace(/(.{2})/g, '$1 ')
        .replace(/\s$/, '')}`
}

export const phoneNumberWithoutInternational = (value: string) => {
    if (!value) return

    if (value.length < 6) return value

    const internationalCode = value.slice(0, 3)

    return `${internationalCode} ${value.slice(3)}`
}

export const uploadManyDocumentsBySolution = async ({
    documents,
    solution_id,
    description,
}) => {
    for await (const document of documents) {
        await Vapor.store(document, {})
            .then(async response => {
                const responseValues: any = {
                    uuid: response.uuid,
                    key: response.key,
                    name: document.name,
                    content_type: document.type,
                    description,
                }

                await uploadFileBySolution({
                    solution_id,
                    documents: [responseValues],
                })
            })
            .catch(error => {
                throw new Error(
                    error || {
                        error: {
                            message: 'Unexpected error',
                        },
                    },
                )
            })
    }
}

export const uploadLicenses = async ({ documents }) => {
    const licensesUploaded = []
    for await (const document of documents) {
        await Vapor.store(document, {})
            .then(async response => {
                licensesUploaded.push(response.key ?? '')
            })
            .catch(error => {
                throw new Error(
                    error || {
                        error: {
                            message: 'Unexpected error',
                        },
                    },
                )
            })
    }

    return licensesUploaded
}

export const checkIsPostcodeCanary = ({ postCode }: { postCode: string }) => {
    const regex = /^(35|38)\d{3}$/

    return regex.test(postCode)
}

export const convertBase64ToBlobUrl = base64 => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)

    const blob = new Blob([byteArray], { type: 'application/pdf' })

    const blobUrl = URL.createObjectURL(blob)
    return blobUrl
}

export const uploadDocumentToS3 = async ({
    document,
}): Promise<IDocumentUploaded> => {
    return await Vapor.store(document, {})
        .then(async response => {
            return {
                ...response,
            }
        })
        .catch(error => {
            throw new Error(
                error || {
                    error: {
                        message: 'Unexpected error',
                    },
                },
            )
        })
}

export async function withPolling<T>(
    cb: (old?: T) => Promise<T>,
    predicate: (data: T) => boolean,
    interval: number = 1000,
) {
    function delayPromise() {
        return new Promise(function (resolve) {
            setTimeout(resolve, interval)
        })
    }

    async function run(old?: T): Promise<T> {
        return cb(old).then(res => {
            if (predicate(res)) {
                return res
            } else {
                return delayPromise().then(() => run(res))
            }
        })
    }

    return run()
}

export async function downloadFromUrl(url: string, filename: string) {
    const { data } = await axios.get<Blob>(url, {
        responseType: 'blob',
    })
    const blob = new Blob([data], { type: data.type })
    const blobUrl = URL.createObjectURL(blob)

    const link = document.createElement('a')

    link.style.display = 'none'
    link.href = blobUrl
    link.setAttribute('download', filename)
    link.setAttribute('target', '_blank')

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
