const DEFAULT_FORMAT: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour12: false,
}

export function formatDate(
    date: string | Date | null,
    options: Intl.DateTimeFormatOptions = {},
) {
    if (!date) return '-'
    const dateObj = new Date(date)

    if (isNaN(dateObj.getTime())) return '-'
    return dateObj.toLocaleString('es-ES', { ...DEFAULT_FORMAT, ...options })
}
